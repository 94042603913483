import { useEffect, useState } from "react";

import { addDays, isAfter } from "date-fns";
import { useTimer } from "react-timer-hook";

// import { useTranslation } from "@/hooks/useTranslation";
import styles from "./Greeting.module.scss";
import { Typography } from "antd";
import React from "react";
const { Paragraph } = Typography;

export const Greeting = () => {
  // const { t } = useTranslation();
  const [greeting, setGreeting] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getDateAtTime = (
    hour: number,
    minute: number,
    second = 0,
    millisecond = 0,
  ) => {
    const date = new Date();
    date.setHours(hour, minute, second, millisecond);
    return date;
  };

  const today = new Date();
  const expiryTimestamp = getDateAtTime(23, 59, 59);
  const morning = getDateAtTime(5, 0);
  const day = getDateAtTime(11, 0);
  const evening = getDateAtTime(17, 0);
  const night = getDateAtTime(22, 0);
  const { isRunning, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  useEffect(() => {
    if (isAfter(today, morning) && isAfter(day, today)) {
      setGreeting("Доброе утро");
      restart(day);
    } else if (isAfter(today, day) && isAfter(evening, today)) {
      setGreeting("Добрый день");
      restart(evening);
    } else if (isAfter(today, evening) && isAfter(night, today)) {
      setGreeting("Добрый вечер");
      restart(night);
    } else {
      setGreeting("Доброй ночи");
      restart(addDays(today, 1));
    }
    setIsLoading(false);
  }, [isRunning]);

  if (isLoading) {
    return null;
  }

  return (
    <Paragraph className={styles.not_print}>
      {greeting}!
    </Paragraph>
  );
};
