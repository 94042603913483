import {
  differenceInCalendarYears,
  differenceInCalendarMonths,
  differenceInCalendarDays,
} from "date-fns/fp";

export const getYearsMonthsDays = (fromDate: string) => {
  const toDate = new Date(); // Текущая дата

  // Вычисление количества лет
  const years = differenceInCalendarYears(fromDate, toDate);

  // Вычисление количества месяцев (минус количество лет)
  const months = differenceInCalendarMonths(fromDate, toDate) - years * 12;

  // Общая разница в днях
  const totalDays = differenceInCalendarDays(fromDate, toDate);

  // Вычисление оставшихся дней
  const daysInFullYears = years * 365; // Примерное количество дней в полных годах
  const daysInFullMonths = months * 31; // Примерное количество дней в полных месяцах
  const remainingDays = totalDays - daysInFullYears - daysInFullMonths;

  return { years, months, remainingDays };
};
