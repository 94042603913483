import React, { useEffect, useMemo, useState } from "react";

import {
  Badge, Button,
  Collapse,
  Divider,
  Flex,
  List, Result,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import {
  differenceInMonths,
  differenceInYears,
  formatWithOptions,
} from "date-fns/fp";
import { ru } from "date-fns/locale";
// import { useTranslation } from 'react-i18next';
import type { Projects } from "./consts";

import styles from "./Experience.module.scss";
import {formatMonths} from "@helpers/getMonthsWord";
import {formatYears} from "@helpers/getYearsWord";
import {getYearsMonthsDays} from "@helpers/getYearsMonthsDays";
import {useGetExperiencesQuery} from "@features/api";
export const Experience = () => {
  // const { t } = useTranslation();
  const { data, error, isLoading } = useGetExperiencesQuery();
  const formatDateMMMM = formatWithOptions({ locale: ru }, "MMMM yyyy");
  const formatDateLLLL = formatWithOptions({ locale: ru }, "LLLL yyyy");
  const [items, setItems] =
    useState<{ id: string; label: string; children: React.ReactNode }[]>();
  const formattedDates = (date: string, isTo: boolean) =>
    isTo ? formatDateLLLL(new Date(date)) : formatDateMMMM(new Date(date));

  const content = (
    id: string,
    from: string,
    to: string | undefined,
    post: string,
    projects: Projects[] | undefined,
    duties: string[],
    skills: string[] | undefined,
  ) => {
    const today = to ? to : `${new Date()}`;
    return (
      <>
        <Typography.Paragraph className={styles.dates_block}>
          {/*{id === "9" ? t("since") : t("from")} {formattedDates(from, false)}{" "}*/}
          {/*/!*{id === "9" && lang !== "ru" ? "–" : t("to")}{" "}*!/*/}
          {/*{id === "9" ? t("present") : formattedDates(today, true)}&nbsp;*/}

          {id === "9" ? "с" : "с"} {formattedDates(from, false)}{" "}
          {/*{id === "9" && lang !== "ru" ? "–" : t("to")}{" "}*/}
          {id === "9" ? "по настоящее время" : "по " + formattedDates(today, true)}&nbsp;
          <Typography.Text className={styles.color_gray}>
            (
            {differenceInYears(from, today) > 0 &&
              `${formatYears(differenceInYears(from, today))} `}
            {differenceInMonths(from, today) % 12 > 0 &&
              formatMonths(differenceInMonths(from, today) % 12)}
            )
          </Typography.Text>
        </Typography.Paragraph>
        {/*&nbsp;{formatDays(item.duration.days)}*/}
        <Typography.Paragraph strong>{post}</Typography.Paragraph>
        {projects && projects?.length > 0 && (
          <Typography.Paragraph>
            {projects.length > 1 ? `Проекты` : `Проект`}
            <Divider type="vertical" />
            <Tooltip
              title={`${projects.length > 1 ? `Ссылки могут быть неактуальными, так как web-ресурсы могли` : `Ссылка может быть неактуальной, так как web-ресурс мог`} переместиться`}
            >
              {projects?.map((items, index) => (
                <Typography.Text key={`${`${id}-${index}`}`}>
                  {index !== 0 && <Divider type="vertical" />}
                  <Typography.Link target="_blank" href={items.url}>
                    {items.name}
                  </Typography.Link>
                </Typography.Text>
              ))}
            </Tooltip>
          </Typography.Paragraph>
        )}
        <Typography.Paragraph>
          <List split={false} size="small">
            {duties?.map((duties, index) => (
              <List.Item key={`${`${id}-${index}`}`} className={styles.list}>
                {duties}
              </List.Item>
            ))}
          </List>
        </Typography.Paragraph>
        <Space direction="horizontal" wrap>
          {skills?.map((badges, index) => (
            <Typography.Text
              key={`${`${id}-${index}`}`}
              className={styles.badge}
            >
              {badges}
            </Typography.Text>
          ))}
        </Space>
      </>
    );
  };

  useEffect(() => {
    if (!isLoading && data) {
      const items = data.map((item) => ({
        key: `${item.id}`,
        id: `${item.id}`,
        label: item.name,
        children: content(
          item.id,
          item.date_from,
          item.date_to,
          item.post,
          item.projects,
          item.duties,
          item.skills,
        ),
      }));
      setItems(items);
    }
  }, [isLoading, data]);

  const workExperience = useMemo(() => {
    if (data) {
      const last = data[Object.keys(data).length - 1];
      const years = getYearsMonthsDays(last.date_from).years;
      const months = getYearsMonthsDays(last.date_from).months;
      // const remainingDays = getYearsMonthsDays(last.date_from).remainingDays;
      // return formatYears(getYearsMonthsDays(last.date_from).years)
      return `${years ? formatYears(years) : null}${" "}${months ? formatMonths(months) : null}`;
      // return `${years ? formatYears(years) : null}${" "}${months ? formatMonths(months) : null}${" "}${remainingDays ? formatDays(remainingDays) : null}`;
    }
  }, [data]);

  if (isLoading) {
    return (
      <Flex justify="center">
        <Spin size="large" />
      </Flex>
    );
  }
  if (error || !data) {
    return (
        <>
          <Typography.Title level={2}>Опыт работы</Typography.Title>
          <Result
              status="404"
              subTitle="Не удалось получить данные, попробуйте обновить страницу"
              extra={<Button href={"/"} type="primary">Обновить</Button>}
          />
        </>
    );
  }
  return (
    <>
      {workExperience && (
        <Badge.Ribbon className={styles.ribbon} text={workExperience}>
          <Typography.Title level={2}>Опыт работы</Typography.Title>
        </Badge.Ribbon>
      )}
      <Collapse
        className={styles.collapse}
        items={items}
        size="large"
        defaultActiveKey={["9", "8", "7", "6", "10", "5"]}
      />
    </>
  );
};
