import { useState } from "react";

import { Collapse, Divider, Space, Typography } from "antd";

import { ModalCertificate } from "@components/ModalCertificate/ModalCertificate";
// import { useTranslation } from "@/hooks/useTranslation";

import type { Certificates } from "./consts";
import { Data } from "./consts";
import styles from "./Courses.module.scss";

export const Courses = () => {
  // const { t, lang } = useTranslation();
  const lang = 'ru'
  const [isModalOpened, seIisModalOpened] = useState<boolean>(false);
  const [isActiveSlide, seIsActiveSlide] = useState<number>(0);
  const openModal = (slide: number | undefined) => {
    seIisModalOpened(true);
    if (slide) {
      seIsActiveSlide(slide);
    }
  };

  const closeModal = () => {
    seIisModalOpened(false);
  };

  const content = (
    to: string,
    specialization: string,
    certificate: Certificates | undefined,
    card: Certificates | undefined,
  ) => (
    <>
      <Typography.Paragraph className={styles.dates_block}>
        {to}
      </Typography.Paragraph>
      <Typography.Paragraph strong>{specialization}</Typography.Paragraph>
      {certificate?.[lang] && (
        <Typography.Link onClick={() => openModal(certificate?.[lang])}>
          Удостоверение о повышении квалификации
        </Typography.Link>
      )}
      {card && (
        <>
          {card?.[lang] && (
            <>
              {certificate?.[lang] && <Divider type="vertical" />}
              <Typography.Link onClick={() => openModal(card?.[lang])}>
                Сертификат
              </Typography.Link>
            </>
          )}
        </>
      )}
    </>
  );

  const items = Data.map((item) => ({
    id: `${item.id}`,
    label: item.name,
    children: content(
      item.to,
      item.specialization,
      item.certificate,
      item.card,
    ),
  }));

  return (
    <>
      <>
        <Typography.Title level={2}>
          Повышение квалификации, курсы
        </Typography.Title>
        <Collapse
          className={styles.collapse}
          items={items}
          size="large"
          defaultActiveKey={["0", "1"]}
        />
      </>
      <ModalCertificate
        isModalOpened={isModalOpened}
        onClose={closeModal}
        activeSlide={isActiveSlide}
      />
    </>
  );
};
