import { Collapse, Divider, Space, Tooltip, Typography } from "antd";

import type { University } from "./consts";
import { Data } from "./consts";
import styles from "./Education.module.scss";
export const Education = () => {
  const content = (
    to: string,
    specialization: University,
    faculty: University,
    degree: string,
  ) => (
    <>
      <Typography.Paragraph className={styles.dates_block}>
        {to}
      </Typography.Paragraph>
      <Space direction="vertical">
        <Typography.Paragraph strong>{degree}</Typography.Paragraph>
        <Typography.Paragraph>
          Факультет
          <Divider type="vertical" />{" "}
          <Tooltip title={`Современное название: «${faculty.current_name}»`}>
            <Typography.Link target="_blank" href={faculty.url}>
              {faculty.name}
            </Typography.Link>
          </Tooltip>
        </Typography.Paragraph>

        <Typography.Paragraph>
          Специальность
          <Divider type="vertical" />
          <Typography.Link target="_blank" href={specialization.url}>
            {specialization.name}
          </Typography.Link>
        </Typography.Paragraph>
      </Space>
    </>
  );

  const labelContent = (university: University) => (
    <Tooltip title={`Современное название: «${university.current_name}»`}>
      {university.name}
    </Tooltip>
  );

  const items = Data.map((item) => ({
    id: `${item.id}`,
    label: labelContent(item.university),
    children: content(item.to, item.specialization, item.faculty, item.degree),
  }));

  if (!Data) {
    return null;
  }
  return (
    <>
      <Typography.Title level={2}>Высшее образование</Typography.Title>
      <Collapse
        className={styles.collapse}
        items={items}
        size="large"
        defaultActiveKey={["0"]}
      />
    </>
  );
};
