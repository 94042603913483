// store/store.ts
import { configureStore } from '@reduxjs/toolkit';
import { experienceApi } from '../features/api';

// Определим тип состояния
export const store = configureStore({
  reducer: {
    [experienceApi.reducerPath]: experienceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(experienceApi.middleware),
});