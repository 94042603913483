export type Certificates = {
  ru: number;
  en?: number;
};

export type DataTypes = {
  id: number;
  name: string;
  to: string;
  specialization: string;
  certificate?: Certificates;
  card?: Certificates;
};

export const Data: DataTypes[] = [
  {
    id: 0,
    name: "Яндекс Практикум",
    to: "2023",
    specialization: "React-разработчик",
    card: {
      ru: 1,
      en: 3,
    },
  },
  {
    id: 0,
    name: "Образовательное частное учреждение дополнительного профессионального образования «Центр компьютерного обучения «Специалист.Ру» Учебно-научного центра при МГТУ им. Н.Э. Баумана»",
    to: "2020",
    specialization: "Vue.js. Расширенные возможности",
    certificate: {
      ru: 4,
    },
    card: {
      ru: 5,
      en: 6,
    },
  },
  {
    id: 1,
    name: "Образовательное частное учреждение дополнительного профессионального образования «Центр компьютерного обучения «Специалист.Ру» Учебно-научного центра при МГТУ им. Н.Э. Баумана»",
    to: "2020",
    specialization:
      "Vue.js - открытый фреймворк на JavaScript для разработки веб-приложений",
    certificate: {
      ru: 7,
    },
    card: {
      ru: 8,
      en: 9,
    },
  },
  {
    id: 2,
    name: 'Центр компьютерного обучения «Специалист» при МГТУ им. Баумана',
    to: "2011",
    specialization: "JavaScript. Основы веб - программирования",
    card: {
      ru: 10,
      en: 11,
    },
  },
  {
    id: 3,
    name: 'Центр компьютерного обучения «Специалист» при МГТУ им. Баумана ',
    to: "2010",
    specialization: "PHP. Разработка web-сайтов и взаимодействие с MySQL",
    card: {
      ru: 12,
      en: 13,
    },
  },
  {
    id: 4,
    name: 'Центр компьютерного обучения «Специалист» при МГТУ им. Баумана ',
    to: "2010",
    specialization: "PHP. Основы создания сайтов",
    card: {
      ru: 14,
      en: 15,
    },
  },
  {
    id: 5,
    name: 'Центр компьютерного обучения «Специалист» при МГТУ им. Баумана ',
    to: "2009",
    specialization: "HTML и CSS. Основы создания сайтов по стандартам W3S",
    card: {
      ru: 16,
      en: 17,
    },
  },
  {
    id: 6,
    name: 'Центр компьютерного обучения «Специалист» при МГТУ им. Баумана ',
    to: "2009",
    specialization: "Основы программирования и баз данных",
    card: {
      ru: 18,
      en: 19,
    },
  },
];
