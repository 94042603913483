import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import type {
  Experience,
  ExperienceResponse,
  NewExperience,
  UpdateExperience,
} from "./types";

export const experienceApi = createApi({
  reducerPath: "api", // Уникальный идентификатор для вашего API среза
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/experience/",
  }), // Базовый URL вашего API
  endpoints: (builder) => ({
    // Получение всех записей опыта
    getExperiences: builder.query<Experience[], void>({
      query: () => "read.php", // Путь к API для получения всех записей
      transformResponse: (response: ExperienceResponse) =>
        response.records.sort((a, b) => {
          // Сравниваем даты (сортируем по убыванию, т.е. от новых к старым)
          const dateA = new Date(a.date_from);
          const dateB = new Date(b.date_from);
          return dateB.getTime() - dateA.getTime(); // Сортировка от новых к старым
        }),
    }),

    // Добавление нового опыта
    createExperience: builder.mutation<Experience, NewExperience>({
      query: (newExperience) => ({
        url: "create.php",
        method: "POST",
        body: newExperience,
      }),
    }),

    // Обновление существующего опыта
    updateExperience: builder.mutation<Experience, UpdateExperience>({
      query: (updatedExperience) => ({
        url: "update.php",
        method: "POST",
        body: updatedExperience,
      }),
    }),

    // Удаление опыта по id
    deleteExperience: builder.mutation<{ message: string }, { id: number }>({
      query: ({ id }) => ({
        url: "delete.php",
        method: "POST",
        body: { id },
      }),
    }),
  }),
});

export const {
  useGetExperiencesQuery,
  useCreateExperienceMutation,
  useUpdateExperienceMutation,
  useDeleteExperienceMutation,
} = experienceApi;
