import React from 'react';
import { Divider, Space, Typography, Image } from 'antd';
import classNames from 'classnames';
import { differenceInYears } from 'date-fns/fp';
import { Greeting } from '@components/Greeting/Greeting';
import { formatYears } from '@helpers/getYearsWord';
import styles from './About.module.scss';
import img from '@assets/images/photo.jpg';

export const About = () => {
  return (
      <>
        <Greeting />
        <Space direction="horizontal" className={styles.info}>
          <Image
              src={img}
              className={classNames(styles.image, styles.not_print)}
          />
          <Space direction="vertical">
            <Typography.Paragraph>
              <Typography.Text className={styles.not_print}>Я, </Typography.Text>
              Ксения Дериёва
              <Typography.Text className={styles.not_print}>, </Typography.Text>
              Frontend &ndash; разработчик
            </Typography.Paragraph>
            <Typography.Text>
              {formatYears(differenceInYears('1986-05-23', new Date()))}
            </Typography.Text>
            <Space direction="horizontal" className={styles.list}>
              <Typography.Link
                  className={styles.link}
                  target="_blank"
                  href="tel:+79250244275"
                  rel="nofollow"
              >
                +7 (925) 024-42-75
              </Typography.Link>
              <Divider className={styles.divider} />
              <Typography.Link
                  rel="nofollow"
                  target="_blank"
                  href="mailto:derievaks@gmail.com?subject=Предложение о работе"
              >
                derievaks@gmail.com
              </Typography.Link>
              <Divider className={styles.divider} />
              <Typography.Link
                  className={styles.link}
                  target="_blank"
                  rel="nofollow"
                  href="https://t.me/makienkoks"
              >
                Telegram
              </Typography.Link>
              <Divider className={styles.divider} />
              <Typography.Link
                  className={styles.link}
                  target="_blank"
                  rel="nofollow"
                  href="https://wa.me/79250244275"
              >
                WhatsApp
              </Typography.Link>
            </Space>
          </Space>
        </Space>
      </>
  );
};
