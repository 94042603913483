// Хелпер для склонения слова "год" в зависимости от числа
const getYearsWord = (count: number): string => {
  if (count % 10 === 1 && count % 100 !== 11) {
    return "год";
  } else if (
    count % 10 >= 2 &&
    count % 10 <= 4 &&
    (count % 100 < 10 || count % 100 >= 20)
  ) {
    return "года";
  }
  return "лет";
};

// Функция для получения строки с количеством лет и правильным склонением
export const formatYears = (count: number): string => {
  const yearsWord = getYearsWord(count);
  return `${count}\u00A0${yearsWord}`;
};
