import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from './store/store';
import App from './App';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';

const rootElement = document.getElementById('root')!;
const root = ReactDOM.createRoot(rootElement);

root.render(
    <ConfigProvider wave={{ disabled: true }}>
        <Provider store={store}>
            <App />
        </Provider>
    </ConfigProvider>
);
