import styles from './App.module.scss';
import '@styles/base.scss';
import {Experience} from "@components/Experience/Experience";
import {About} from "@components/About/About";
import {Education} from "@components/Education/Education";
import {Courses} from "@components/Courses/Courses";
import {Affix, Flex, Menu, Space} from "antd";

function App() {
  return (
      <>
          <Affix
              className={styles.not_print}
              offsetTop={0}
              style={{ backgroundColor: "#fff" }}
          >
              <Flex vertical className={styles.header}>
                  <Flex
                      justify="space-between"
                      gap={16}
                      wrap={false}
                      className={styles.content}
                  >
                      <Menu
                          className={styles.menu}
                          mode="horizontal"
                          items={[
                              {
                                  label: <a href="#main">Обо мне</a>,
                                  key: "main",
                              },
                              {
                                  label: <a href="#experience">Опыт работы</a>,
                                  key: "experience",
                              },
                              {
                                  label: <a href="#education">Образование</a>,
                                  key: "education",
                              },
                              {
                                  label: <a href="#courses">Курсы</a>,
                                  key: "courses",
                              },
                          ]}
                      />
                  </Flex>
              </Flex>
          </Affix>
        <div className={styles.page}>
            <Space direction={"vertical"} className={styles.block} id="main">
                <About />
            </Space>
            <Space direction={"vertical"} className={styles.block} id="experience">
                <Experience />
            </Space>
            <Space direction={"vertical"} className={styles.block} id="education">
                <Education/>
            </Space>
            <Space direction={"vertical"} className={styles.block} id="courses">
                <Courses />
            </Space>
        </div>
          <Flex vertical className={styles.footer}>
              <Flex
                  justify="center"
                  gap={16}
                  wrap={false}
                  className={styles.content}
              >
                  &copy; Copyright {new Date().getFullYear()}
              </Flex>
          </Flex>
      </>
  );
}

export default App;
