// Хелпер для склонения слова "месяц" в зависимости от числа
function getMonthsWord(count: number): string {
  if (count % 10 === 1 && count % 100 !== 11) {
    return "месяц";
  } else if (
    count % 10 >= 2 &&
    count % 10 <= 4 &&
    (count % 100 < 10 || count % 100 >= 20)
  ) {
    return "месяца";
  }
  return "месяцев";
}

// Функция для получения строки с количеством месяцев и правильным склонением
export const formatMonths = (count: number): string => {
  const monthsWord = getMonthsWord(count);
  return `${count}\u00A0${monthsWord}`;
};
