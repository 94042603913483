export type University = {
  name: string;
  url: string;
  current_name?: string;
};

export type DataTypes = {
  id: number;
  to: string;
  university: University;
  faculty: University;
  specialization: University;
  degree: string;
};

export const Data: DataTypes[] = [
  {
    id: 0,
    university: {
      name: "Курский государственный технический университет",
      url: "https://swsu.ru",
      current_name: "Юго-западный государственный университет",
    },
    to: "2008",
    faculty: {
      name: "Приборостроительный",
      url: "https://swsu.ru/structura/up/fivt/",
      current_name:
        "Факультет фундаментальной и прикладной информатики (ФФиПИ)",
    },
    specialization: {
      name: "Проектирование и технология электронно-вычислительных средств",
      url: "https://swsu.ru/structura/up/fivt/kvt/index.php",
    },
    degree: "Специалист",
  },
  {
    id: 2,
    university: {
      name: "Курский государственный технический университет",
      url: "https://swsu.ru",
      current_name: "Юго-западный государственный университет",
    },
    to: "2007",
    faculty: {
      name: "Приборостроительный",
      url: "https://swsu.ru/structura/up/fivt/",
      current_name:
        "Факультет фундаментальной и прикладной информатики (ФФиПИ)",
    },
    specialization: {
      name: "Проектирование и технология электронно-вычислительных средств",
      url: "https://swsu.ru/structura/up/fivt/kvt/index.php",
    },
    degree: "Бакалавр",
  },
];
